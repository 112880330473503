<template>
  <Navbar :whitetheme="whitetheme" navbaron="album"/>
  <ListAlbum />
  <ScrollToTop />
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import ListAlbum from '@/components/ComponentAlbum/ListAlbum.vue';
import ScrollToTop from '@/components/ScrollToTop.vue';

export default {
  data(){
    return{
      whitetheme: true,
    }
  },
  components:{
    Navbar,
    ListAlbum,
    ScrollToTop,
  },
  methods: {scrollup(){
        window.scroll({
            top: 0,
            left: 0,
        })
    },
  }
}
</script>