import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import axios from 'axios'
import Echo from 'laravel-echo'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

var baseUrl = window.location.origin
// axios.defaults.baseURL = 'http://localhost:8000/api';

if(process.env.NODE_ENV === 'staging'){
	axios.defaults.baseURL = 'https://staging-api.kitaserbadigital.com/api';
}else if(process.env.NODE_ENV === 'production'){
	axios.defaults.baseURL = 'https://core.kitaserbadigital.com/api';
}else{
	axios.defaults.baseURL = 'http://localhost:8000/api';
}

window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '9eca3d670ba2979a9dd5',
    cluster: 'ap1',
    wsHost: '172.16.2.214',
    wsPort: 6001,
    forceTLS: false,
    disableStats: true
})

const app = createApp(App).use(router).use(VueSweetalert2).mount('#app')
