<template>
  <div v-if="isMounted" id="landingtemplate">
    <Navbar :whitetheme="whitetheme" navbaron="home"/>
    <Beranda id="home" :contents="slogan" :image="image" />
    <TentangKSD :tentangKami="tentangKami" id="about" />
    <CarouselComponent :color1="'#e1a1ed'" :color2="'#ac7df1'" :color3="'#7658f4'" :headertitle="'Produk Kami'" :contents="products"/>
    <Gallery id="gallery" ref="gallerycomponent" :galleryItems="gallery"/>
    <Mitra id="mitra" :color1="'#e1a1ed'" :color2="'#ac7df1'" :color3="'#7658f4'" :headertitle="'Mitra'" :mitra1="mitra1" :mitra2="mitra2"/>
    <OurTeam :karyawan="karyawan" :ourteamcontent="ourteamcontent"/>
    <FAQ id="faq" :accordions="faqs"/>
    <Footer :kontak="kontak" id="contact"/>
    <ScrollToTop />
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue';
import Beranda from '@/components/Beranda.vue';
import Gallery from '@/components/Gallery.vue';
import CarouselComponent from '@/components/Carousel.vue';
import Mitra from '@/components/Mitra.vue';
import OurTeam from '@/components/OurTeam.vue';
import FAQ from '@/components/FAQ.vue';
import TentangKSD from '@/components/TentangKSD.vue';
import Footer from '@/components/Footer.vue';
import ScrollToTop from '@/components/ScrollToTop.vue';
import axios from 'axios';
export default {
  name: "App",
  components: {
    Navbar,
    Beranda,
    Gallery,
    CarouselComponent,
    Mitra,
    OurTeam,
    FAQ,
    TentangKSD,
    Footer,
    ScrollToTop,
  },
  data(){
    return{
      whitetheme: true,
      slogan: [
        {id: 1, title: "Slogan", description: "Inovasi Tanpa Data Adalah Inovasi Tanpa Solusi, Data Tanpa Inovasi Adalah Solusi Yang Tidak Berdampak Pada Jalan Keluar Yang Dibutuhkan", color: '#bf00fe'},
        {id: 2, title: "Visi", description: "", color: '#bf00fe'},
        {id: 3, title: "Misi", description: "", color: '#bf00fe'},
        {id: 4, title: "Misi", description: "", color: '#bf00fe'},
      ],
      image: require('@/assets/background.jpeg'),
      products: [
        {id: 1, image: require("@/assets/loader.gif")},
        {id: 2, image: require("@/assets/loader.gif")},
        {id: 3, image: require("@/assets/loader.gif")},
      ],
      faqs: [
        {id: 1, question: 'Loading Pertanyaan...', answer: 'Loading Jawaban...'},
      ],
      mitra1: [
        {id: 0, image: require("@/assets/loader.gif")},
        {id: 1, image: require("@/assets/loader.gif")},
        {id: 2, image: require("@/assets/loader.gif")},
        {id: 3, image: require("@/assets/loader.gif")},
        {id: 4, image: require("@/assets/loader.gif")},
      ],
      mitra2: [
        {id: 0, image: require("@/assets/loader.gif")},
        {id: 1, image: require("@/assets/loader.gif")},
        {id: 2, image: require("@/assets/loader.gif")},
        {id: 3, image: require("@/assets/loader.gif")},
        {id: 4, image: require("@/assets/loader.gif")},
      ],
      gallery:[
        {id: 0, type: 'image', videoid: '', src: require("@/assets/loader.gif")},
        {id: 1, type: 'image', videoid: '', src: require("@/assets/loader.gif")},
        {id: 2, type: 'image', videoid: '', src: require("@/assets/loader.gif")},
        {id: 3, type: 'image', videoid: '', src: require("@/assets/loader.gif")},
        {id: 4, type: 'image', videoid: '', src: require("@/assets/loader.gif")},
        {id: 5, type: 'image', videoid: '', src: require("@/assets/loader.gif")},
        {id: 6, type: 'image', videoid: '', src: require("@/assets/loader.gif")},
        {id: 7, type: 'image', videoid: '', src: require("@/assets/loader.gif")},
      ],
      tentangKami: [],
      karyawan:[],
      kontak: {foto: require("@/assets/loader.gif")},
      ourteamcontent: [
        {id: 1, name: "Firman", division: "CEO", image: require("@/assets/loader.gif")},
        {id: 2, name: "Firman", division: "CEO", image: require("@/assets/loader.gif")},
        {id: 3, name: "Firman", division: "CEO", image: require("@/assets/loader.gif")},
        {id: 4, name: "Firman", division: "CEO", image: require("@/assets/loader.gif")},
        {id: 5, name: "Firman", division: "CEO", image: require("@/assets/loader.gif")},
        {id: 6, name: "Firman", division: "CEO", image: require("@/assets/loader.gif")}
      ],
      isMounted: false,
    }
  },
  methods: {
    init(){
      this.getVisiMisi()
      this.getTentangKami()
      this.getGallery()
      this.getKaryawan()
      this.getKontak()
      this.getProduk()
      this.getMitra()
      this.getFaq()
    },
    getVisiMisi(){
      axios.get(`visi_misi`)
        .then(res=>{
          this.slogan.forEach((data, index) => {
            if(data.id == 2){
              this.slogan[index].description = res.data.visi
            }else if(data.id == 3){
              this.slogan[index].description = res.data.misi
            }else if(data.id == 4){
              this.slogan[index].description = res.data.misi2;
            }
          })
          
        })
        .catch(err => {
          console.log(err.response.data)
        })
    },
    getTentangKami(){
      axios.get(`tentang_kami`)
        .then(res => {
          this.tentangKami = res.data
        })
        .catch(err => {
          console.log(err.response.data)
        })
    },
    getKaryawan(){
      var reqOne = axios.get(`karyawan`);
      var reqTwo = axios.get(`getCarouselKaryawan`);
      
      axios.all([reqOne, reqTwo]).then(axios.spread((...res) => {
        this.karyawan = res[0].data;
        this.ourteamcontent = res[1].data;
      })).catch(err => {
        console.log(err.message);
      })
    },
    getGallery(){
      axios.get(`gallery`)
        .then(res => {
          this.gallery = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getKontak(){
      axios.get('kontak')
        .then(res => {
          this.kontak = res.data
        }).catch(err => {
          console.log(err.message)
        })
    },
    getProduk(){
      axios.get('produk')
        .then(res => {
          this.products = res.data
        }).catch(err => {
          console.log(err.message)
        })
    },
    getMitra(){
      axios.get(`mitra`)
        .then(res => {
          this.mitra1 = res.data.mitra1
          this.mitra2 = res.data.mitra2
        }).catch(err => {
          console.log(err.message)
        })
    },
    getFaq(){
      axios.get('faq')
        .then(res => {
          this.faqs = res.data
        }).catch(err => [
          console.log(err.message)
        ])
    },
    scrollup(){
        window.scroll({
            top: 0,
            left: 0,
        })
    },
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  mounted(){
    this.isMounted = true;
    this.scrollup()
  },
}
</script>
<style scoped>
@import url('@/assets/css/bootstrap.min.css');
@import url('@/assets/css/all.min.css');
@import url('@/assets/css/animate.css');
@import url('@/assets/css/nice-select.css');
@import url('@/assets/css/owl.min.css');
@import url('@/assets/css/magnific-popup.css');
@import url('@/assets/css/flaticon.css');
@import url('@/assets/css/main.css');
</style>
