<template v-if="listAlbum">
  <div class="p-8">
    <div class="flex flex-wrap md:justify-between xxs:justify-center">
        <h1 class="text-4xl font-bold pb-8 md:w-1/2 xxs:w-full">List Album</h1>
        <div class="flex items-center self-start pb-8 xxs:w-5/6 md:w-1/2">
            <input class="rounded-3xl border-2 mr-2 bg-transparent focus:border-purple-600 focus:border-2 border-purple-600 px-4" type="text" v-model="data" @keydown="checkInput" @keyup.enter="findAlbum" placeholder="Tekan enter untuk cari album">
        </div>
    </div>
    <div class="bgimage fixed opacity-50 bottom-0 left-0 w-full h-full -z-10"></div>
    <div class="flex flex-wrap" v-if="!isSearching">
        <div v-for="album in listAlbum" :key="album.id" class="px-8 lg:w-1/3 md:w-1/2 xxs:w-full sm:mb-12 xxs:mb-12 cursor-pointer" @click="albumDetails(album.id, album.title)">
            <p class="text-center text-xl font-bold break-words h-16">{{coba(album.title)}}</p>
            <!-- <p class="text-center text-xl font-bold break-words h-16">{{album.title.split('_').join(' ')}}</p> -->
            <div class="w-full h-48 bg-cover bg-no-repeat bg-center" :style="'background-image: url('+album.image+')'"></div>
            <p class="text-center text-lg">{{album.date}}</p>
        </div>
    </div>
    
    <div v-if="!isData">Tidak ada Album yang bernama {{ data }}</div>
    
    <div class="flex flex-wrap" v-else>
        <div v-for="album in albumData" :key="album.id" class="px-8 lg:w-1/3 md:w-1/2 xxs:w-full sm:mb-12 xxs:mb-12 cursor-pointer" @click="albumDetails(album.id,album.title)">
            <p class="text-center text-xl font-bold break-words h-16">{{coba(album.title)}}</p>
            <div class="w-full h-48 bg-cover bg-no-repeat bg-center" :style="'background-image: url('+album.image+')'"></div>
            <p class="text-center text-lg">{{album.date}}</p>
        </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import axios from 'axios';

export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data(){
        return {
            data: '',
            isSearching: false,
            isData: true,
            isError: false,
            errMsg: '',
            albumData: [],
            listAlbum: []
        }   
    },
    methods: {
        getAlbums(){
            this.isSearching = false
            this.albumData = [];
            this.isError = false;
            
            axios.get(`albums`)
                .then(res=>{
                    this.listAlbum = res.data
                })
                .catch(err => {
                    this.isError = true
                    this.errMsg = err.message
                })
        },
        findAlbum(){
            if(this.data){
                this.isSearching = true;
                this.albumData = [];
                this.isData = true;
                this.isError = false;
                
                axios.get(`albums/${this.data}`)
                    .then(res => {
                        this.albumData = res.data
                    })
                    .catch(err => {
                        this.isData = false
                        this.isError = true
                        this.errMsg = err.message
                    })
            }else{
                console.log('tidak ada input')
                this.getAlbums()
            }
        },
        albumDetails(id,title){
            localStorage.idAlbum = id
            this.$router.push({name: 'AlbumDetails', params: { id, title}})
        },
        checkInput(){
            if(this.data.length < 2){
                this.isSearching = false
                this.getAlbums();
            }
        },
        coba(value){
            var strSplit = [];
            value.split('_').forEach((data, index) => {
                strSplit[index] = data.charAt(0).toUpperCase() + data.substring(1);
            })
            
            return strSplit.join(' ');
        }
    },
    created(){
        this.getAlbums();
    },
}
</script>

<style scoped>
.bgimage{
    background-image: url('@/assets/beritabg.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.bgima{
    background-image: url('@/assets/logo.png')
}
.kategori{
    background: linear-gradient(240deg, #C56FE1 0%, #CB81F2 15%, #8F41F1 100%);
    border-radius: 12px;
    color: white;
    padding: 4px 12px;
    height: 40px;
    margin: 0 12px 24px 0;
    text-align: center;
}
</style>