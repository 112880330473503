<template>
  <div id="beritatemplate">
    <Navbar :whitetheme="whitetheme" navbaron="berita"/>
    <Headline :categories="categoryleft" :headline="headline" style=""/>
    <CarouselBerita class="lg:block md:block sm:hidden xxs:hidden hilang" :numOfElements=3 :carouselBerita="carouselBerita"/>
    <CarouselBerita class="lg:hidden md:hidden sm:block xxs:block hilang" :numOfElements=1 :carouselBerita="carouselBerita"/>
    <ListBerita :listBerita="listBerita" class="hilang"/>
    <ScrollToTop />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Headline from '@/components/ComponentBerita/Headline.vue';
import CarouselBerita from '@/components/ComponentBerita/CarouselBerita.vue';
import ListBerita from '@/components/ComponentBerita/ListBerita.vue';
import ScrollToTop from '@/components/ScrollToTop.vue';
import axios from 'axios';
export default {
    data(){
        return{
            whitetheme: true,
            categoryleft: [
                {id: 1, text: 'Loading...'},
                {id: 2, text: 'Loading...'},
                {id: 3, text: 'Loading...'},
                {id: 4, text: 'Loading...'},
                {id: 5, text: 'Loading...'},
                {id: 6, text: 'Loading...'},
            ],
            headline: {title: 'Headline', description: 'Loading Deskripsi Text', image: require('@/assets/berita.png')},
            carouselBerita: [
                {id: 1, title: 'Loading Judul...', image: require('@/assets/berita.png'), date: '05 Febuari 2022'},
                {id: 2, title: 'Loading Judul...', image: require('@/assets/berita.png'), date: '05 Febuari 2022'},
                {id: 3, title: 'Loading Judul...', image: require('@/assets/berita.png'), date: '05 Febuari 2022'},
                {id: 4, title: 'Loading Judul...', image: require('@/assets/berita.png'), date: '05 Febuari 2022'},
                {id: 5, title: 'Loading Judul...', image: require('@/assets/berita.png'), date: '05 Febuari 2022'},
            ],
            listBerita: [
                {id: 1, title: 'Loading Judul...', description: 'Loading Deskripsi...', image: require('@/assets/berita.png'), datetime: 'Loading Tanggal...', categories: ['Loading...', 'Loading...', 'Loading...']},
                {id: 2, title: 'Loading Judul...', description: 'Loading Deskripsi...', image: require('@/assets/berita.png'), datetime: 'Loading Tanggal...', categories: ['Loading...', 'Loading...']},
                {id: 3, title: 'Loading Judul...', description: 'Loading Deskripsi...', image: require('@/assets/berita.png'), datetime: 'Loading Tanggal...', categories: ['Loading...', 'Loading...', 'Loading...']},
            ],
        }
    },
    components: {
        Navbar,
        Headline,
        CarouselBerita,
        ListBerita,
        ScrollToTop,
    },
    methods: {
        scrollup(){
            window.scroll({
                top: 0,
                left: 0,
            })
        }
    },
    created(){
        axios.get(`berita`)
            .then(res => {
                this.headline = res.data.headline;
                this.categoryleft = res.data.category;
                this.listBerita = res.data.berita;
                this.carouselBerita = res.data.random;
            })
            .catch(err => {
                console.log(err.message)
            })
    },
    mounted(){
        this.scrollup()
    }
}
</script>

<style>
.hilangbeneran{
    display: none !important;
}
</style>