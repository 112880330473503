<template v-if="!!carouselBerita">
  <div class="p-4">
    <h1 class="text-4xl font-bold">Berita Trending</h1>
    <div class="">
        <swiper :modules="modules"
            :slides-per-view="numOfElements"
            :space-between="50"
            :loop="true"
            :pagination="{ clickable: true }"
            :breakpoints="swiperOptions.breakpoints"
            :autoplay="{ delay: 3500, disableOnInteraction: false }"
            @slideChange="onSlideChange" class="pt-8 pb-12 flex justify-center items-center">
            <swiper-slide @click="beritaDetails(berita.id,berita.slug)" v-for="berita in carouselBerita" class="flex justify-center px-4 hover:cursor-pointer" :key="berita.id">
                <div class="relative h-48 w-full bg-cover bg-no-repeat bg-center" :style="'background-image: url('+berita.image+')'">
                    <div class="lg:p-4 md:p-2 xxs:p-4 absolute left-0 top-0 w-full h-full opacity-0 hover:opacity-100 transition-all duration-300">
                        <div class="relative w-full h-full">
                            <div class="absolute bottom-0 left-0">
                                <h1 class="font-bold text-white z-10 relative lg:text-3xl md:text-lg sm:text-3xl xxs:text-2xl text-left">{{berita.title}}</h1>
                                <p class="text-white z-10 relative lg:text-lg md:text-base sm:text-lg xxs:text-lg text-left">{{berita.date}} - {{ berita.views }} Views</p>
                            </div>
                        </div>
                        <div class="bg-black z-0 opacity-50 w-full h-full absolute left-0 top-0"></div>
                    </div>
                </div>
            </swiper-slide>
        </swiper> 
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import axios from 'axios';

export default {
    props: ['carouselBerita', 'numOfElements'],
    components: {
        Swiper,
        SwiperSlide
    },
    data(){
        return{
        slidesPerView: 3,
        spaceBetween: 30,
        // Responsive breakpoints
        swiperOptions: {
            breakpoints: {       
                320: {       
                slidesPerView: 1,
                spaceBetween: 10     
                },          
                640: {       
                slidesPerView: 2,       
                spaceBetween: 50     
                },   
                1024: {       
                slidesPerView: 3,       
                spaceBetween: 30     
                } 
            
            }
        },
        }
    },
    setup() {
        const onSwiper = (swiper) => {
        };
        const onSlideChange = () => {
        };
        return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
        };
    },
    methods: {
        beritaDetails(id, slug){
            localStorage.idBerita = id
            this.updateBerita(id)
            this.$router.push({name: 'BeritaDetails', params: {id,slug}})
        },
        updateBerita(id){
            axios.put(`berita/${id}`)
            .catch(err => {
                console.log(err.message)
            })
        }
    }
}
</script>