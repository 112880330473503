<template>
  <div>
    <div class="text-purple-600 text-center text-3xl mt-5">
        <img src="@/assets/logo.png" alt="" class="mt-10 w-60 h-auto m-auto">
        <div>Input Kode Meeting : <input @keyup.enter="enterCode" id="inputcode" type="text" name="kodemeeting" class="w-60 text-xl rounded-3xl bg-purple-600 text-white pl-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    enterCode(){
      var temporary = document.getElementById('inputcode').value
      if(temporary){
        this.$router.push("/meetingmanagement/"+temporary)
      }
    }
  }
}
</script>

<style>

</style>