<template>
  <div class = "main"><div class = "wrapper">,
        <img src="@/assets/logo.png" class="w-96 h-auto m-auto" alt="">
        <p class="text-5xl text-center">Page Not Found</p>
        <router-link :to="{ path: '/home' }" href="#"
        class="block text-center py-2 pr-4 pl-3 hover:text-purple-600 lg:text-xl">
            Back to Home
        </router-link>
  </div></div>
</template>

<script>
export default {
    methods: {
    }
}
</script>

<style scoped>
html, body {
    height: 100%;
}
.main {
    height: 100%;
    width: 100%;
    display: table;
}
.wrapper {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    padding-top: 10% !important;
}
</style>