<template v-if="!!contents">                                
<section class="testimonial-section padding-top padding-bottom pt-lg-half ">
    <div class="container" style="max-width: 90% !important">
        <div class="section-header">
            <h5 class="cate text-3xl" style="color: #8D41F2">FAQ</h5>
            <h2 class="title">Jawaban untuk semua pertanyaan anda</h2>
        </div>
        <!-- <div class="testimonial-wrapper">
            <a href="#0" class="testi-prev trigger">
                <img src="@/assets/images/client/right.png" alt="client">
            </a>
            <a href="#0" class="testi-next trigger">
                <img src="@/assets/images/client/left.png" alt="client">
            </a>
            <swiper :modules="modules"
              :slides-per-view="1"
              :loop="true"
              ref="swiperfaq"
              :navigation="{
                nextEl: '.testi-prev',
                prevEl: '.testi-next'
              }"
              :autoHeight="true"
              :breakpoints="swiperOptions.breakpoints"
              :autoplay="{ delay: 3500, disableOnInteraction: false }"
              @slideChange="onSlideChange" class="pt-16 pb-12 flex justify-center items-center">
              <swiper-slide v-for="content in contents" class="flex justify-center testimonial-item p-2" :key="content.id">
                <div>
                  <h1 class="text-5xl sm:text-5xl xxs:text-3xl mb-1 text-center">{{content.question}}</h1>
                  <h3 class="text-2xl sm:text-xl xxs:text-base break-all">{{content.answer}}</h3>
                </div>
              </swiper-slide>
            </swiper>
        </div> -->
        <div v-for="accordion in accordions" :key="accordion.id" class="mb-3">
          <button :id="'accordion'+accordion.id" @click="openAccordion(accordion.id)" class="accordion xs:text-xl xxs:text-sm font-bold text-white bg-slate-300 h-auto">{{accordion.question}}</button>
          <div class="panel text-md bg-white break-words">
            <p class="py-2 xs:text-md xxs:text-sm">{{accordion.answer}}</p>
          </div>
        </div>
        <div class="relative">
          <button class="accordioninput active xs:text-xl xxs:text-sm font-bold text-white bg-slate-300 h-auto">Punya Pertanyaan Lain?</button>
          <button @click="inputAccordion" class="bg-white text-purple-600 text-center absolute md:block xxs:hidden right-2 w-36 rounded-full !h-8 border-none font-bold" style="bottom: 6px">Submit</button>
        </div> 
        <div class="panelinput relative bg-white">
          <textarea id="textareafaq" class="pt-2 px-3 border-none bg-white break-words resize-none h-28 xs:text-md xxs:text-sm" type="text" placeholder="Silahkan input disini" @keydown="charAmount" @keyup="charAmount" maxlength="200"></textarea>
          <p id="charamount" class="absolute text-slate-300 text-sm bottom-2 right-2">0 / 200</p>
        </div>
        <button @click="inputAccordion" class="bg-white my-4 text-purple-600 text-center md:hidden xxs:block right-2 rounded-full !h-8 w-full border-none font-bold" style="bottom: 6px">Submit</button>
    </div>
</section>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default {
  props: ['contents', 'accordions'],  
  components: {
    Swiper,
    SwiperSlide
  },
  data(){
    return{
      slidesPerView: 1,
      spaceBetween: 0,
      // Responsive breakpoints
      swiperOptions: {
          breakpoints: {       
            320: {       
              slidesPerView: 1,
              spaceBetween: 0     
            },          
            640: {       
              slidesPerView: 1,       
              spaceBetween: 0     
            },   
            771: {       
              slidesPerView: 1,       
              spaceBetween: 0     
            } 
        
        }
      },
    }
  },
  methods: {
    openAccordion(accordionid){
      var accorcount = document.getElementsByClassName('accordion');
      for(var i = 0; i < accorcount.length; i++){
        accorcount[i].classList.remove("active");
        accorcount[i].nextElementSibling.style.maxHeight = null;
      }
      document.getElementById('accordion'+accordionid).classList.toggle("active");
      var panel = document.getElementById('accordion'+accordionid).nextElementSibling;
      if (panel.style.maxHeight) {  
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      } 
    },
    charAmount(){
      var textchar = document.getElementById('charamount')
      var faqarea = document.getElementById('textareafaq')
      var lengthfaq = faqarea.value.length

      textchar.innerHTML = lengthfaq + ' / 200'
    },
    inputAccordion(){
      var isi = document.getElementById('textareafaq').value
      if(isi.length > 0){
        axios.post('faq/add', {
          pertanyaan: isi
        })
        .then((response) =>{
          if(response.data.status == 'berhasil'){
            this.$swal('Berhasil', 'Pertanyaan anda berhasil terkirim', 'success');
            document.getElementById('textareafaq').className += " outline outline-green-300";
          }else{
            this.$swal('Oops', 'Pertanyaan anda tidak terkirim, silahkan cek jaringan', 'danger');
            document.getElementById('textareafaq').className += " outline outline-red-500";
          }
        })
        .catch((error) =>{
          console.log(error.message);
        })
      }
    }
  },
  setup() {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
}
</script>
:color1="'#'" :color2="'#ac7df1'" :color3="'#7658f4'
<style scoped>
@import url('@/assets/css/bootstrap.min.css');
@import url('@/assets/css/all.min.css');
@import url('@/assets/css/animate.css');
@import url('@/assets/css/nice-select.css');
@import url('@/assets/css/owl.min.css');
@import url('@/assets/css/magnific-popup.css');
@import url('@/assets/css/flaticon.css');
@import url('@/assets/css/main.css');

.accordion, .accordioninput{
  cursor: pointer;
  padding: 12px 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
}

.active, .accordion:hover {
  background-color: #ccc;
}

.accordion.active, .accordioninput.active{
  background: linear-gradient(240deg, #e1a1ed 0%, #ac7df1 15%, #7658f4 100%);
}

.panel, .panelactive {
  padding: 0px 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
</style>