<template>
  <Navbar :whitetheme="whitetheme" navbaron="berita"/>
  <div v-if="isLoading">Loading...</div>
  <div v-if="error"> {{ error }}</div>
  <div v-if="berita">
    <BeritaDetail :beritaLain="beritaLain" :berita="berita"/>
  </div>
  <ScrollToTop />
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import BeritaDetail from '@/components/ComponentBerita/BeritaDetail.vue';
import ScrollToTop from '@/components/ScrollToTop.vue';
import axios from 'axios';

export default {
  data(){
    return{
      whitetheme: true,
      berita: null,
      beritaLain: [],
      isLoading: false,
      error: null,
    }
  },
  components:{
    Navbar,
    BeritaDetail,
    ScrollToTop,
  },
  methods: {
    getBerita(){
      axios.get(`berita`)
        .then(res => {
          this.beritaLain = res.data.random
        })
    },
    scrollup(){
        window.scroll({
            top: 0,
            left: 0,
        })
    }
  },
  beforeRouteEnter(to, from, next) {
    if(!localStorage.idBerita){
      axios.get(`berita/${to.params.id}`)
        .then(res => {
          next(vm => {
            localStorage.idBerita = to.params.id;
            vm.berita = res.data
          })
        })
        .then(err => {
          next(vm => {
            this.$router.push('Berita')
            vm.error = err.message
            console.log(vm.error)
          })
        })
    }else{
      axios.get(`berita/${localStorage.idBerita}`)
        .then(res => {
          next(vm => {
            vm.berita = res.data
          })
        })
        .then(err => {
          next(vm => {
            this.$router.push('Berita')
            vm.error = err.message
            console.log(vm.error)
          })
        })
    }
  },
  mounted(){
    this.scrollup();
    this.getBerita();
  },
  beforeRouteLeave(to, from){
    localStorage.removeItem('idBerita')
  }
}
</script>