<template v-if="!!mitra1 && !!mitra2">
<div>
  <div ref="containerref" class="button-container block pb-12">
    <h1 class="overflow-y-hidden pt-16 m-auto text-center text-white text-4xl">{{headertitle}}</h1>
    <!-- <carousel :items-to-show="5.5" :items-to-scroll="1" class="pt-16" :wrap-around="true" :breakpoints="breakpoints" :autoplay="1000">
      <slide v-for="m1 in mitra1" :key="m1.id">
        <img :src="m1.image" alt="" class="w-6/12">
      </slide>
    </carousel>
    <carousel :items-to-show="5.5" :items-to-scroll="1" class="py-8" :wrap-around="true" :breakpoints="breakpoints" :autoplay="1500">
      <slide v-for="m2 in mitra2" :key="m2.id">
        <img :src="m2.image" alt="" class="w-6/12">
      </slide>
    </carousel>
    <carousel :items-to-show="5.5" :items-to-scroll="1" class="pb-16" :wrap-around="true" :breakpoints="breakpoints" :autoplay="2000">
      <slide v-for="m3 in mitra3" :key="m3.id">
        <img :src="m3.image" alt="" class="w-6/12">
      </slide>
    </carousel> -->
    <swiper :modules="modules"
      :slides-per-view="4"
      :space-between="50"
      :loop="true"
      :breakpoints="swiperOptions.breakpoints"
      :autoplay="{ delay: 2000, disableOnInteraction: false }"
      @slideChange="onSlideChange" class="pt-16 pb-4 flex justify-center items-center">
      <swiper-slide v-for="m1 in mitra1" class="flex justify-center" :key="m1.id">
          <img :src="m1.image" alt="" class="h-20">
      </swiper-slide>
    </swiper>
    <swiper :modules="modules"
      :slides-per-view="4"
      :space-between="50"
      :loop="true"
      :breakpoints="swiperOptions.breakpoints"
      :autoplay="{ delay: 3000, disableOnInteraction: false, reverseDirection: true }"
      @slideChange="onSlideChange" class="pt-4 pb-4 flex justify-center items-center">
      <swiper-slide v-for="m2 in mitra2" class="flex justify-center" :key="m2.id">
          <img :src="m2.image" alt="" class="h-20">
      </swiper-slide>
    </swiper>
    <!-- <swiper :modules="modules"
      :slides-per-view="4"
      :space-between="50"
      :loop="true"
      :breakpoints="swiperOptions.breakpoints"
      :autoplay="{ delay: 4500, disableOnInteraction: false }"
      @slideChange="onSlideChange" class="pt-4 pb-12 flex justify-center items-center">
      <swiper-slide v-for="m3 in mitra3" class="flex justify-center" :key="m3.id">
          <img :src="m3.image" alt="" class="h-20">
      </swiper-slide>
    </swiper> -->
  </div>
</div>

</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default {
  props: ['headertitle', 'color1', 'color2', 'color3', 'mitra1', 'mitra2'],
  components: {
    Swiper,
    SwiperSlide
  },
  data(){
    return{
      slidesPerView: 4,
      spaceBetween: 30,
      // Responsive breakpoints
      swiperOptions: {
          breakpoints: {       
            320: {       
              slidesPerView: 2,
              spaceBetween: 10     
            },          
            640: {       
              slidesPerView: 3,       
              spaceBetween: 30     
            },   
            771: {       
              slidesPerView: 4,       
              spaceBetween: 30     
            } 
        }
      },
    }
  },
  methods: {
    changeCarouselBg(){
      this.$refs.containerref.style.background = 'linear-gradient(16deg, '+this.color1+' 0%, '+this.color2+' 65%, '+this.color3+' 100%)'
    },
  },
  setup() {
    const onSwiper = (swiper) => {
    };
    const onSlideChange = () => {
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  mounted(){
    this.changeCarouselBg()
  },  
}
</script>

<style scoped>
@import url('@/assets/css/bootstrap.min.css');
@import url('@/assets/css/all.min.css');
@import url('@/assets/css/animate.css');
@import url('@/assets/css/nice-select.css');
@import url('@/assets/css/owl.min.css');
@import url('@/assets/css/magnific-popup.css');
@import url('@/assets/css/flaticon.css');
@import url('@/assets/css/main.css');

*{
  overflow-x: hidden;
}
.nextbutton img, .prevbutton img{
  background: #262f6e;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: 0 -20%;
}

.nextbutton, .prevbutton{
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  transition: 0.5s;
}

.nextbutton{
  left: 20px;
}

.prevbutton{
  right: 20px;
}

.button-container{
  position: relative;
}
</style>