<template v-if="!!tentangKami">
    <div id="about" class=" rounded-t-lg mt-lg-n2 py-12 lg:px-8 xxs:px-2 m-auto justify-center">
        <p class="font-bold lg:text-5xl md:text-4xl sm:text-4xl xxs:text-4xl text-center pb-8" style="color: #8D41F2">Tentang Kami</p>
        <div class="grid lg:grid-cols-3 md:px-5 sm:grid-cols-1 xxs:grid-cols-1 px-4 gap-8">
            <center v-for="tentang in tentangKami" :key="tentang.id">
                <img :src="tentang.image" class="h-96 xxs:h-64" alt="Siapa?"/>
                <p class="font-bold text-4xl p-4 xxs:text-3xl capitalize" style="color: #8D41F2">{{ tentang.judul }}</p>
                <span class="xxs:text-sm text-justify capitalize md:text-xl lg:text-base break-words" v-html="tentang.deskripsi"></span>
            </center>
        </div>
    </div>
</template>
<script>
export default{
    props: ['tentangKami']
}
</script>