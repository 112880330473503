<template>
<div v-if="pageLoad" class="h-full">
    <div v-if="memberAda" class="h-full">
        <div class="bgkartu xxs:p-2">
            <div id="hidebar" class="mt-4 mx-auto text-center h-full" style="overflow-y: scroll">
                <div class="md:flex md:h-full align-items-center testclass">
                    <div class="leftside md:w-5/12 md:mr-40 xxs:mx-auto xxs:mb-12 xxs:w-10/12">
                        <img :src="imageMember" alt="" class="gambarteam rounded-full h-auto m-auto md:w-64 xxs:w-9/12">
                        <h1 class="text-white font-bold capitalize mt-4 md:text-2xl xxs:text-lg">{{namaMember}}</h1>
                        <h2 class="text-white font-regular capitalize mt-1 md:text-xl xxs:text-md">{{jabatanMember}}</h2>
                    </div>
                    <div class="md:w-7/12 mx-auto px-8">
                        <img class="lg:w-40 xxs:w-20 mx-auto d-block" src="@/assets/logoputih.png" alt="">
                        <p class="text-white md:mb-16 md:text-xl xxs:text-md xxs:mb-8">PT. KITA SERBA DIGITAL</p>
                        <div class="flex align-items-center mb-4">
                            <img src="@/assets/whatsappputih.png" class="w-8 h-8 mr-3" alt="">
                            <a :href="'https://wa.me/'+noHPMember" class="text-white text-left md:text-xl xxs:text-md">{{noHPMember}}</a>
                        </div>
                        <div class="flex align-items-center mb-4">
                            <img src="@/assets/instagramputih.png" class="w-8 h-8 mr-3" alt="">
                            <a :href="'https://www.instagram.com/'+instagramMember+'/'" class="text-white text-left md:text-xl xxs:text-md">{{instagramMember}}</a>
                        </div>
                        <div class="flex align-items-center mb-4">
                            <img src="@/assets/emailputih.png" class="w-8 h-8 mr-3" alt="">
                            <p class="text-white text-left md:text-xl xxs:text-md">{{emailMember}}</p>
                        </div>
                        <div class="flex align-items-center">
                            <img src="@/assets/mapsputih.png" class="w-8 h-8 mr-3" alt="">
                            <p class="text-white text-left md:text-xl xxs:text-md">Jl. Sulawesi Gg. Keluarga No. 7, Pontianak Selatan, Kota Pontianak, Kalimantan Barat</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-purple-600 text-center text-3xl mt-5 mx-auto" style="width: 70%" v-else>
        <img src="@/assets/logo.png" alt="" class="mt-10 w-60 h-auto m-auto">
        Maaf, member dengan link ini tidak ada, silahkan periksa kembali link anda
    </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            memberAda: false,
            pageLoad: false,
            temporaryvar: [],
            namaMember: '',
            jabatanMember: '',
            emailMember: '',
            instagramMember: '',
            noHPMember: '',
            imageMember: ''
        }
    },
    methods: {
        mountfunction(){
            axios.get(`detailTeam`)
            .then(res=>{
                this.pageLoad = true
                var temporaryvar = res.data.filter((member)=>{
                    if(member.kode == this.$route.params.kode) return member.kode
                })
                if(temporaryvar.length > 0){
                    this.memberAda = true
                    this.namaMember = temporaryvar[0].nama
                    this.jabatanMember = temporaryvar[0].jabatan
                    this.emailMember = temporaryvar[0].email
                    this.instagramMember = temporaryvar[0].instagram
                    this.noHPMember = temporaryvar[0].no_hp
                    this.imageMember = temporaryvar[0].image
                }
            })
        }
    },
    mounted(){
        this.mountfunction()
        document.body.classList.add('kartu')
    }
}
</script>

<style>
.bgkartu{
    margin-top: 2rem;
    padding: 3rem 4rem;
    width: auto;
    height: 100%;
    margin: 0 auto;
    background-size: cover;
    background-image: url('@/assets/Kartunama.png');
    background-repeat: no-repeat, repeat;
    background-position: center;
}

.gambarteam{
    border: 5px solid rgb(36, 3, 58);
}

@media(max-width: 767px) {
    .testclass{
        height: 1000px !important;
    }
}

@media screen and (min-width: 768px){
    .gambarteam{
        width: 50% !important;
    }
    .leftside{
        width: 66.66% !important;
        margin: 0 !important;
        margin-right: 2rem !important;
    }
    .testclass{
        height: 600px !important;
    }
}

#hidebar::-webkit-scrollbar { 
    display: none; 
}
</style>