import { createRouter, createWebHistory } from 'vue-router'
import AlbumDetails from '../views/AlbumDetails.vue'
import Album from '../views/Album.vue'
import BeritaDetails from '../views/BeritaDetails.vue'
import Berita from '../views/Berita.vue'
import Landing from '../views/Landing.vue';
import NotFound from '../views/NotFound.vue';
import MeetingManagement from '../views/MeetingManagement.vue';
import MeetingManagementEvent from '../views/MeetingManagementEvent.vue';
import OurTeam from '../views/OurTeam.vue';
import DetailOurTeam from '../views/DetailOurTeam.vue';

const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/:id',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/album/:title',
    name: 'AlbumDetails',
    component: AlbumDetails,
    params: true,
    beforeEnter(to, from, next){
      if(!localStorage.idAlbum){
        next('album')
      }else{
        next()
      }
    }
  },
  {
    path: '/album',
    name: 'Album',
    component: Album
  },
  {
    path: '/berita',
    name: 'Berita',
    component: Berita
  },
  {
    path: '/berita/:slug',
    name: 'BeritaDetails',
    component: BeritaDetails,
    params: true,
    beforeEnter: (to, from, next) => {
      if(!localStorage.idBerita){
        next('berita');
      }else{
        next();
      }
    }
  },
  {
    path: '/ourteam',
    name: 'OurTeam',
    component: OurTeam
  },
  {
    path: '/ourteam/:kode',
    name: 'DetailOurTeam',
    component: DetailOurTeam,
  },
  {
    path: '/meetingmanagement',
    name: 'MeetingManagement',
    component: MeetingManagement,
  },
  {
    path: '/meetingmanagement/:kode',
    name: 'MeetingManagementEvent',
    component: MeetingManagementEvent,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeResolve(to => {
//   console.log(to.params.id)
// })


export default router