<template v-if="ourteamcontent && karyawan">
<div>
<div class="w-full ourteambg relative lg:block xxs:hidden h-screen px-0 pt-3" style="height: 590px !important" v-for="item in karyawan" :key="item.id">
      
      <div class="">
        <div class="flex w-full">
          <div class="flex justify-center w-full min-w-0">
              <div class="pt-8 pr-3" style="width: 50%;"    >
                  <h1 class="font-bold text-right text-sm">{{item.namaHP}}</h1>
                  <p class="text-sm text-right pb-2 border-b-2" style="border-color: rgba(0,0,0,0.2)">{{item.jabatanHP}}</p>
              </div>
              <div style="width: 160px; height: 160px" class="">
                  <img class="w-full h-full rounded-full" :src="item.imageHP" alt="">
              </div>
          </div>
          <div class="flex justify-center w-full min-w-0">
              <div class="pt-8 pr-3" style="width: 50%;">
                  <h1 class="font-bold text-right text-sm">{{item.namaHN}}</h1>
                  <p class="text-sm text-right pb-2 border-b-2" style="border-color: rgba(0,0,0,0.2)">{{item.jabatanHN}}</p>
              </div>
              <div style="width: 160px; height: 160px" class="">
                  <img class="w-full h-full rounded-full" :src="item.imageHN" alt="">
              </div>
          </div>
          <div class="flex justify-center w-full min-w-0">
              <div class="pt-8 pr-3" style="width: 50%;">
                  <h1 class="font-bold text-right text-sm">{{item.namaHI}}</h1>
                  <p class="text-sm text-right pb-2 border-b-2" style="border-color: rgba(0,0,0,0.2)">{{item.jabatanHI}}</p>
              </div>
              <div style="width: 160px; height: 160px" class="">
                  <img class="w-full h-full rounded-full" :src="item.imageHI" alt="">
              </div>
          </div>
        </div>
        <div class="flex w-full absolute bottom-3">
          <div class="flex justify-center w-full min-w-0">
              <div style="width: 160px; height: 160px;">
                  <img class="w-full h-full rounded-full" :src="item.imageHR" alt="">
              </div>
              <div class="pt-8 pl-3" style="width: 50%;">
                  <h1 class="font-bold text-sm">{{item.namaHR}}</h1>
                  <p class="text-sm pb-2 border-b-2" style="border-color: rgba(0,0,0,0.2)">{{item.jabatanHR}}</p>
              </div>
          </div>
          <div class="flex justify-center w-full min-w-0">
              <div style="width: 160px; height: 160px;">
                  <img class="w-full h-full rounded-full" :src="item.imagePC" alt="">
              </div>
              <div class="pt-8 pl-3" style="width: 50%;">
                  <h1 class="font-bold text-sm">{{item.namaPC}}</h1>
                  <p class="text-sm pb-2 border-b-2" style="border-color: rgba(0,0,0,0.2)">{{item.jabatanPC}}</p>
              </div>
          </div>
          <div class="flex justify-center w-full min-w-0">
              <div style="width: 160px; height: 160px;">
                  <img class="w-full h-full rounded-full" :src="item.imageCEO" alt="">
              </div>
              <div class="pt-8 pl-3" style="width: 50%;">
                  <h1 class="font-bold text-sm">{{item.namaCEO}}</h1>
                  <p class="text-sm pb-2 border-b-2" style="border-color: rgba(0,0,0,0.2)">{{item.jabatanCEO}}</p>
              </div>
          </div>
        </div>
      </div>
        <div style="top: 50%" class="absolute left-2/4 -translate-x-2/4 -translate-y-2/4 w-full">
          <h1 class="text-white text-4xl font-bold text-center">Our Team</h1>
          <p class="text-white text-base text-center relative">PT. Kita Serba Digital</p>
      </div>
      <router-link :to="{ name: 'OurTeam' }" href="#" class="font-bold text-white text-xl absolute right-4" style="bottom: 38%">
        See More >
      </router-link>
  </div>
  <div class="w-full ourteambg relative lg:hidden xxs:block" style="height: 700px">
    <swiper :modules="modules"
        :slides-per-view="1"
        :loop="true"
        :pagination="{ clickable: true }"
        :breakpoints="swiperOptions.breakpoints"
        :autoplay="{ delay: 3500, disableOnInteraction: false }"
        @slideChange="onSlideChange" class="pt-16 pb-12 flex justify-center items-center md:pt-32 sm:pt-54 xxs:pt-36">
        <swiper-slide v-for="member in ourteamcontent" class="flex justify-center flex-col items-center" :key="member.id">
            <img :src="member.image" alt="" class="md:w-4/12 sm:w-6/12 xxs:w-6/12 m-auto">
            <div style="background: rgba(0,0,0,0.5)" class="rounded-xl p-3 w-auto">
                <h1 class="sm:text-4xl xxs:text-2xl text-white font-bold text-center block border-b-2 border-slate-300">{{member.name}}</h1>
                <p class="sm:text-xl xxs:text-lg text-white text-center block">{{member.division}}</p>
            </div>
        </swiper-slide>
    </swiper>
    <div class="absolute top-8 left-8 w-full">
        <h1 class="text-white md:text-5xl sm:text-3xl xxs:text-2xl font-bold">Our Team</h1>
        <p class="text-white text-base md:w-full sm:w-1/2 xxs:w-1/2">PT. Kita Serba Digital</p>
    </div>
      <router-link :to="{ name: 'OurTeam' }" href="#" class="font-bold text-white text-2xl absolute right-4 bottom-4">
        See More >
      </router-link>
  </div>
</div>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default {
    props: ['karyawan', 'ourteamcontent'],
    components: {
        Swiper,
        SwiperSlide
    },
    data(){
        return{
        slidesPerView: 1,
        spaceBetween: 0,
        // Responsive breakpoints
        swiperOptions: {
            breakpoints: {       
                320: {       
                slidesPerView: 1,
                spaceBetween: 0     
                },          
                640: {       
                slidesPerView: 1,       
                spaceBetween: 0     
                },   
                771: {       
                slidesPerView: 1,       
                spaceBetween: 0     
                } 
            
            }
        },
        }
    },
    setup() {
        const onSwiper = (swiper) => {
        };
        const onSlideChange = () => {
        };
        return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
        };
    },
}
</script>

<style scoped>
.ourteambg{
    background-image: url('@/assets/ourteambg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media (max-width: 1024px){
  .ourteambg{
    background-image: url('@/assets/ourteambglarge.png') !important;
  }
}
</style>