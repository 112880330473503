<template>
  <div class="h-full">
    <router-view />
  </div>
</template>
<script>
export default{
  mounted(){
    console.log(process.env.NODE_ENV)
  }
}
</script>
<style>
html { height: 100%; overflow:auto; }
body.kartu { height: 100%; }

#app{
  height: 100%;
}

body{
  position: relative;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #7023ec;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 23, 172); 
}
.swiper-wrapper{
  align-items: center;
}

@media (max-width: 768px){
  .swiper-button-next, .swiper-button-prev{
    display: none !important;
  }
}

@media screen and (min-width: 1px){
  body{
    overflow-y: hidden;
  }
}

#beritatemplate .swiper-pagination-bullet-active{
  background: #9333ea !important;
}

#landingtemplate .swiper-pagination-bullet-active{
  background: rgba(255,255,255,0.7) !important;
}
</style>